<template>
  <div class="product-description-list" :class="themeColorClass">
    <UiHeader
      v-if="header"
      class="product-description-list__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <div class="product-description-list__wrapper">
      <div v-if="firstItemList.length" class="product-description-list__list">
        <T3HtmlParser
          v-if="bodytext"
          class="product-description-list__text"
          :content="bodytext"
        />

        <ProductDescriptionListItem
          v-for="item in firstItemList"
          :key="item.id"
          :item="item"
        />
      </div>

      <div v-else-if="bodytext" class="product-description-list__list">
        <T3HtmlParser
          class="product-description-list__text"
          :content="bodytext"
        />
      </div>

      <div v-if="secondItemList.length" class="product-description-list__list">
        <ProductDescriptionListItem
          v-for="item in secondItemList"
          :key="item.id"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiCeProductBoxesProps } from '~/types'
import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'

import ProductDescriptionListItem from './ProductDescriptionListItem.vue'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)

const splitItemList = computed(() => {
  const splitIndex = Math.floor(props.itemList.length / 2)

  return [props.itemList.slice(0, splitIndex), props.itemList.slice(splitIndex)]
})

const [firstItemList, secondItemList] = splitItemList.value
</script>

<style lang="scss">
.product-description-list {
  counter-reset: product-description-number;
  padding-bottom: rem(40px);

  &__heading {
    width: 80%;

    @include media-query(md) {
      width: 50%;
    }

    & > * {
      font-weight: 600;
      margin-bottom: rem(24px);
    }
  }

  &__wrapper {
    display: flex;
    gap: rem(25px);

    @include media-query(max-width md) {
      flex-direction: column;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(32px);
    width: 100%;

    @include media-query(md) {
      &:first-child {
        width: 54%;
      }

      &:last-child {
        width: 46%;
      }
    }
  }

  &__text {
    font-size: rem(15px);
    margin-top: rem(12px);
    padding-right: rem(24px);

    @include themed-link;
  }
}
</style>
